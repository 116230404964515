// import React from 'react'
// import {Flex, Button, Image, Link} from '@chakra-ui/react'
// import logo from './logo3s.png' 
// const Navbar = () => {
//   return (
    
//     <Flex>
//             <Image
        
//         pos='fixed'
//         top='1rem'
//         left='2rem'
//     h='70px'
//     w='110px'
//     objectFit='wrap'
//     src={logo}
//     alt='3S'
//   />
//         <Flex
//         pos='fixed'
//         top='1rem'
//         right='1rem'
        
//         >
    
//             <Flex p='2' >
//              <Link href='/#'>   <Button >Home</Button></Link>
//             </Flex>
//             <Flex p='2'>
//              <Link href='/#'>   <Button >Payment</Button></Link>
//             </Flex>
//             {/* <Flex p='2' > */}
//              {/* <Link href='/#'>   <Button colorScheme='blue' >Login</Button></Link>
//             </Flex> */}
//         </Flex>
        
        
//         </Flex>
//   )
// }

// export default Navbar
import React, { useState } from 'react';
import { Flex, Button, Image, Link } from '@chakra-ui/react';
import logo from './logo3s.png';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Flex
      pos="fixed"
      top="0"
      left="0"
      right="0"
      py="1rem"
      px={{ base: "1rem", md: "2rem" }}
      justifyContent="space-between"
      alignItems="center"
      bg="white"
      boxShadow="md"
      zIndex="1"
    >
      <Image
        h={{ base: "50px", md: "70px" }}
        w={{ base: "80px", md: "110px" }}
        objectFit="wrap"
        src={logo}
        alt="3S"
      />
      <Flex
        display={{ base: "flex", md: "none" }}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="outline"
          colorScheme="blue"
          onClick={handleMobileMenuToggle}
        >
          {isMobileMenuOpen ? 'Close' : 'Menu'}
        </Button>
        {isMobileMenuOpen && (
          <Flex
            flexDirection="column"
            alignItems="center"
            bg="white"
            boxShadow="md"
            p="2rem"
            mt="1rem"
            borderRadius="md"
          >
            <Flex p="2">
              <Link href='/#'>
                <Button>Home</Button>
              </Link>
            </Flex>
            <Flex p="2">
              <Link href='/#'>
                <Button>Payment</Button>
              </Link>
            </Flex>
            {/* <Flex p="2">
              <Link href='/#'>
                <Button colorScheme="blue">Login</Button>
              </Link>
            </Flex> */}
          </Flex>
        )}
      </Flex>
      <Flex
        display={{ base: "none", md: "flex" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex p="2">
          <Link href='/#'>
            <Button>Home</Button>
          </Link>
        </Flex>
        <Flex p="2">
          <Link href='/#'>
            <Button>Payment</Button>
          </Link>
        </Flex>
        {/* <Flex p="2">
          <Link href='/#'>
            <Button colorScheme="blue">Login</Button>
          </Link>
        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default Navbar;