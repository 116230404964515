/* eslint-disable react-hooks/rules-of-hooks */
import { Fragment } from "react";
import {
  Container,
  Box,
  chakra,
  Flex,
  VStack,
  Grid,
  Divider,
  Link,
  Image,
  useColorModeValue,
  
} from "@chakra-ui/react";

import { motion } from "framer-motion";
const articles = [
  {
    title: "Voltas Limited, Vadodara (30TR AC Laboratory)",
    link: "#",
    description:
      "",
    logo: "https://via.placeholder.com/100x50?text=Voltas", // Replace with actual logo URL
  },
  {
    title: "Haier Appliances, Noida, AC & Refrigerator Testing Laboratory",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier", // Replace with actual logo URL
  },
  {
    title: "Haier Appliances, Noida, Washing Machine Lab. (NABL & Lab setup)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier",
  },
  {
    title: "Haier Appliances (AC&REF), Pune",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier",
  },
  {
    title: "Haier Appliances, Gr Noida, NABL Scope Enhancement",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier",
  },
  {
    title: "Haier Appliances, Pune (Commercial AC Lab, NABL)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Haier",
  },
  {
    title: "Samsung, Chennai AC and REF Labs",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Samsung", // Replace with actual logo URL
  },
  {
    title: "LG Electronics, REF Lab, Pune",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=LG", // Replace with actual logo URL
  },
  {
    title: "PwC, Gurugram",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=PwC", // Replace with actual logo URL
  },
  {
    title: "Voltas Limited, Vadodara (BIS-QCO)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Voltas", // Replace with actual logo URL
  },
  {
    title: "Voltas-Beko, Sanand, Ahmedabad",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Voltas", // Replace with actual logo URL
  },
  {
    title: "Voltas Ltd., Vadodara (600 TR Chiller Labs)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Voltas", // Replace with actual logo URL
  },
  {
    title: "EPACK Durables, Dehradun",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=EPACK", // Replace with actual logo URL
  },
  {
    title: "Ahmedabad Municipal Corporation, Ahmedabad",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=AMC", // Replace with actual logo URL
  },
  {
    title: "Gemcare Appliances Pvt. Ltd., Halol, Gujarat (NABL & BIS)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Gemcare", // Replace with actual logo URL
  },
  {
    title: "Amber Enterprises India Ltd., Jhajjar",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Amber", // Replace with actual logo URL
  },
  {
    title: "Carrier Midea India Ltd., Supa, Ahmednagar",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Carrier", // Replace with actual logo URL
  },
  {
    title: "AECOM, Gurugram",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=AECOM", // Replace with actual logo URL
  },
  {
    title: "GACL, Vadodara",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=GACL", // Replace with actual logo URL
  },
  {
    title: "SKP Projects, Vadodara",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=SKP", // Replace with actual logo URL
  },
  {
    title: "PG Technoplast Pvt. Ltd., Supa, Maharashtra",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=PG Technoplast", // Replace with actual logo URL
  },
  {
    title: "National Test House, Mumbai",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=NTH", // Replace with actual logo URL
  },
  {
    title: "Amber Enterprises, Sri City",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Amber", // Replace with actual logo URL
  },
  {
    title: "Johnson Controls (India) Pvt. Ltd., Pune",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Johnson Controls", // Replace with actual logo URL
  },
  {
    title: "Softhard Automation Pvt. Ltd., Pune",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Softhard", // Replace with actual logo URL
  },
  {
    title: "Kirloskar Chillers, Pune (NABL Accreditation)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Kirloskar", // Replace with actual logo URL
  },
  {
    title: "Dixon Electro Manf Pvt. Ltd., Gr Noida (REF Plant, NABL & BIS)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Dixon", // Replace with actual logo URL
  },
  {
    title: "Swegon Blue Box Pvt. Ltd., Thane (Chiller Labs, NABL)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=Swegon", // Replace with actual logo URL
  },
  {
    title: "ACE Test House, Delhi (Washing Machine Lab setup & NABL)",
    link: "#",
    logo: "https://via.placeholder.com/100x50?text=ACE", // Replace with actual logo URL
  },
];

const Articles = () => {
  const bg = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.100", "gray.700");
  const titleColor = useColorModeValue("blue.600", "blue.300");

  return (
    <Container maxW="5xl" p={{ base: 5, md: 10 }}>
      <Flex justifyContent="left" mb={6}>
        <chakra.h3 fontSize="2xl" fontWeight="bold" color={titleColor}>
          Our Esteemed Customers
        </chakra.h3>
      </Flex>
      <VStack
        border="1px solid"
        borderColor="gray.400"
        rounded="md"
        boxShadow="lg"
        overflow="hidden"
        spacing={0}
        bg={bg}
      >
        {articles.map((article, index) => (
          <Fragment key={index}>
            <Box
              as={motion.div}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 90.5,
                delay: index * 20, // Staggered animation for each article
                ease: "easeInOut",
              }}
              whileHover={{
                scale: 1.02,
                boxShadow: "xl",
                transition: { duration: 0.3 },
              }}
            >
              <Grid
                templateRows={{ base: "auto", md: "auto" }}
                w="100%"
                templateColumns={{ base: "6fr 1fr 1fr", md: "6fr 1fr 1fr" }}
                p={{ base: 4, sm: 6 }}
                gap={3}
                alignItems="center"
                _hover={{
                  bg: hoverBg,
                  transform: "translateY(-2px)",
                  transition: "all 0.3s ease",
                }}
              >
                <Box>
                  <chakra.h3
                    as={Link}
                    href={article.link}
                    isExternal
                    fontWeight="bold"
                    fontSize={{ base: "md", sm: "lg" }}
                    color={titleColor}
                    _hover={{ textDecoration: "underline" }}
                  >
                    {article.title}
                  </chakra.h3>
                  {article.description && (
                    <chakra.p fontSize="sm" mt={2}>
                      {article.description}
                    </chakra.p>
                  )}
                </Box>
                <Box justifySelf="right">
                  <Image
                    src={article.logo}
                    alt={`${article.title} logo`}
                    maxW="160px"
                    h="auto"
                    marginRight={0}
                  />
                </Box>
              </Grid>
            </Box>
            {index < articles.length - 1 && <Divider m={0} />}
          </Fragment>
        ))}
      </VStack>
    </Container>
  );
};

export default Articles;
// /* eslint-disable react-hooks/rules-of-hooks */
// import { Fragment } from "react"
// import {
//   Container,
//   Box,
//   chakra,
//   Flex,
//   VStack,
//   Grid,
//   Divider,
//   Link,
//   useColorModeValue
// } from "@chakra-ui/react"

// const articles = [
//   {
//     title: "Ahmedabad Municipal Corporation, Ahmedabad    ",
//     link:
//       "#",
//     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi quod dolorum eaque vol'

//   },
//   {
//     title: "Voltas Limited, Vadodara (30TR AC Laboratory)",
//     link:
//       "#",

//   },
//   {
//     title: "Haier Appliances, Noida, AC Testing Laboratory",
//     link: "#",

//   },
//   {
//     title: "Samsung, Chennai AC and REF Labs",
//     link:
//       "#",

//   },
//   {
//     title: "LG Electronics, REF Lab, Pune",
//     link:
//       "#",

//   }
// ]

// const Articles = () => {
//   return (
//     <Container maxW="5xl" p={{ base: 5, md: 10 }}>
//       <Flex justifyContent="left" mb={3}>
//         <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
//           Our Esteemed Customers
//         </chakra.h3>
//       </Flex>
//       <VStack
//         border="1px solid"
//         borderColor="gray.400"
//         rounded="md"
//         overflow="hidden"
//         spacing={0}
//       >
//         {articles.map((article, index) => (
//           <Fragment key={index}>
//             <Grid
//               templateRows={{ base: "auto auto", md: "auto" }}
//               w="100%"
//               templateColumns={{ base: "unset", md: "4fr 2fr 2fr" }}
//               p={{ base: 2, sm: 4 }}
//               gap={3}
//               alignItems="center"
//               _hover={{ bg: useColorModeValue("gray.200", "gray.700") }}
//             >
//               <Box gridColumnEnd={{ base: "span 2", md: "unset" }}>
//                 <chakra.h3
//                   as={Link}
//                   href={article.link}
//                   isExternal
//                   fontWeight="bold"
//                   fontSize="lg"
//                 >
//                   {article.title}
//                 </chakra.h3>

//               </Box>

//             </Grid>
//             {articles.length - 1 !== index && <Divider m={0} />}
//           </Fragment>
//         ))}
//       </VStack>
//     </Container>
//   )
// }
// export default Articles;
