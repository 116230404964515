// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useRef, useState } from 'react';
// import { Button, Container, Heading, Stack, Text, usePrefersReducedMotion } from '@chakra-ui/react';
// import { motion } from 'framer-motion';

// const HeroSec = () => {
//   const [isVisible, setIsVisible] = useState(false);
//   const containerRef = useRef(null);
//   const prefersReducedMotion = usePrefersReducedMotion();

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setIsVisible(entry.isIntersecting);
//       },
//       {
//         threshold: 0.9, // Adjust this value to trigger the effect sooner or later
//       }
//     );

//     if (containerRef.current) {
//       observer.observe(containerRef.current);
//     }

//     return () => {
//       if (containerRef.current) {
//         observer.unobserve(containerRef.current);
//       }
//     };
//   }, []);

//   const variants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: { opacity: 1, y: 0 },
//   };

//   return (
//     <Container maxW={"3xl"} ref={containerRef}>
//       <Stack
//         as={motion.div}
//         textAlign={"center"}
//         spacing={{ base: 8, md: 14 }}
//         py={{ base: 20, md: 36 }}
//         initial="hidden"
//         animate={isVisible ? "visible" : "hidden"}
//         variants={variants}
//         transition={{
//           duration: prefersReducedMotion ? 0 : 5.5, 
//           ease: "easeInOut",
//         }}
//       >
//         <Heading
//           fontWeight={600}
//           fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
//           lineHeight={"110%"}
//           color={"blue.400"}
//         >
//           Energy Care & Consulting Services <br />
//           <Text as={"span"} color={"green.400"}>
//             For your Business
//           </Text>
//         </Heading>
//         <Text color={"gray.500"}>
//           More than 2.5 decades of experience in Energy Efficiency <br />
//           and Consultancy services
//         </Text>
//         <Stack
//           direction={"column"}
//           spacing={3}
//           align={"center"}
//           alignSelf={"center"}
//           position={"relative"}
//         >
//           <Button
//             colorScheme={"green"}
//             bg={"green.400"}
//             rounded={"full"}
//             px={6}
//             _hover={{
//               bg: "green.500",
//             }}
//           >
//             Get Started
//           </Button>
//         </Stack>
//       </Stack>
//     </Container>
//   );
// };

// export default HeroSec;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Heading, Stack, Text, usePrefersReducedMotion } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const HeroSec = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.9, // Adjust this value to trigger the effect sooner or later
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const spring = {
    type: "spring",
    stiffness: 260,
    damping: 20,
  };

  return (
    <Container maxW="full" ref={containerRef}>
      <Stack
        as={motion.div}
        textAlign={"center"}
        spacing={{ base: 8, md: 14 }}
        py={{ base: 20, md: 36 }}
        minHeight="8vh" // Set min-height to cover the full screen
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={variants}
        transition={prefersReducedMotion ? { duration: 0 } : spring}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
          color={"blue.400"}
        >
          Energy Care & Consulting Services <br />
          <Text as={"span"} color={"green.400"}>
            For your Business
          </Text>
        </Heading>
        <Text color={"gray.500"}>
          More than 2.5 decades of experience in Energy Efficiency <br />
          and Consultancy services
        </Text>
        <Stack
          direction={"column"}
          spacing={3}
          align={"center"}
          alignSelf={"center"}
          position={"relative"}
        >
          <Button
            colorScheme={"green"}
            bg={"green.400"}
            rounded={"full"}
            px={6}
            _hover={{
              bg: "green.500",
            }}
            whileHover={{ scale: 1.05 }} // Add a hover animation
          >
            Get Started
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default HeroSec;