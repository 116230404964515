// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect, useRef } from 'react';
// import {
//   Box,
//   Button,
//   Container,
//   FormControl,
//   FormLabel,
//   Input,
//   Textarea,
//   Heading,
//   VStack,
//   Flex,
//   useColorModeValue,
//   usePrefersReducedMotion
// } from '@chakra-ui/react';
// import { motion } from 'framer-motion';

// const ContactForm = () => {
//   const [isVisible, setIsVisible] = useState(false);
//   const containerRef = useRef(null);
//   const prefersReducedMotion = usePrefersReducedMotion();
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [message, setMessage] = useState('');

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setIsVisible(entry.isIntersecting);
//       },
//       {
//         threshold: 0.9, // Adjust this value to trigger the effect sooner or later
//       }
//     );

//     if (containerRef.current) {
//       observer.observe(containerRef.current);
//     }

//     return () => {
//       if (containerRef.current) {
//         observer.unobserve(containerRef.current);
//       }
//     };
//   }, []);

//   const variants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: { opacity: 1, y: 0 },
//   };

//   const bg = useColorModeValue("white", "gray.900");
//   const buttonColor = useColorModeValue("blue.500", "blue.300");
//   const textColor = useColorModeValue("blue.500", "gray.300");

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const formData = {
//       name,
//       email,
//       phone,
//       message,
//     };
//     try {
//       const response = await fetch('http://localhost:5000/api/contact', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });
//       if (response.ok) {
//         console.log('Form submitted successfully!');
//         // Reset form fields
//         setName('');
//         setEmail('');
//         setPhone('');
//         setMessage('');
//       } else {
//         console.error('Error submitting form:', response.status);
//       }
//     } catch (error) {
//       console.error('Error submitting form:', error);
//     }
//   };

//   return (
//     <Container maxW="container.md" py={10} p={{ base: 2, md: 8 }} ref={containerRef}>
//       <VStack spacing={6} align="stretch">
//         <VStack
//           as={motion.div}
//           spacing={4}
//           initial="hidden"
//           animate={isVisible ? "visible" : "hidden"}
//           variants={variants}
//           transition={{
//             duration: prefersReducedMotion ? 0 : 1.5, // Adjust duration as needed
//             ease: "easeInOut",
//           }}
//         >
//           <Box bg={bg}>
//             <Heading size="lg" mb={2} color={textColor}>
//               Your Contact
//             </Heading>
//           </Box>
//           <FormControl id="name" isRequired>
//             <FormLabel>Name</FormLabel>
//             <Input
//               type="text"
//               placeholder="Enter your name"
//               value={name}
//               onChange={(event) => setName(event.target.value)}
//             />
//           </FormControl>
//           <FormControl id="email" isRequired>
//             <FormLabel>Email</FormLabel>
//             <Input
//               type="email"
//               placeholder="Enter your email"
//               value={email}
//               onChange={(event) => setEmail(event.target.value)}
//             />
//           </FormControl>
//           <FormControl id="phone" isRequired>
//             <FormLabel>Phone Number</FormLabel>
//             <Input
//               type="tel"
//               placeholder="Enter your phone number"
//               value={phone}
//               onChange={(event) => setPhone(event.target.value)}
//             />
//           </FormControl>
//           <FormControl id="message" isRequired>
//             <FormLabel>Message</FormLabel>
//             <Textarea
//               placeholder="Enter your message"
//               value={message}
//               onChange={(event) => setMessage(event.target.value)}
//             />
//           </FormControl>
//           <Flex w="full" justifyContent="flex-end">
//             <Button
//               colorScheme="blue"
//               bg={buttonColor}
//               type="submit"
//               onClick={handleSubmit}>
//               Save
//             </Button>
//           </Flex>
//         </VStack>
//       </VStack>
//     </Container>
//   );
// };

// export default ContactForm;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Heading,
  VStack,
  Flex,
  useColorModeValue,
  usePrefersReducedMotion,
  useToast
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

const ContactForm = () => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [result, setResult] = useState(""); // New state for result
  const toast = useToast();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.9, // Adjust this value to trigger the effect sooner or later
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const bg = useColorModeValue("white", "gray.900");
  const buttonColor = useColorModeValue("blue.500", "blue.300");
  const textColor = useColorModeValue("blue.500", "gray.300");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("message", message);
    formData.append("access_key", "cd16e7d0-4d9f-40cb-b725-a78b1161c5fe");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Contact Submitted Successfully");
      toast({
        title: "Form Submitted Successfully",
        description: "",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
    } else {
      console.log("Error", data);
      setResult(data.message);
      toast({
        title: "Error",
        description: data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
    }
  };

  return (
    <Container maxW="container.md" py={10} p={{ base: 2, md: 8 }} ref={containerRef}>
      <VStack spacing={6} align="stretch">
        <VStack
          as={motion.div}
          spacing={4}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={variants}
          transition={{
            duration: prefersReducedMotion ? 0 : 1.5, // Adjust duration as needed
            ease: "easeInOut",
          }}
        >
          <Box bg={bg}>
            <Heading size="lg" mb={2} color={textColor}>
              Your Contact
            </Heading>
          </Box>
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormControl>
          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormControl>
          <FormControl id="phone" isRequired>
            <FormLabel>Phone Number</FormLabel>
            <Input
              type="tel"
              placeholder="Enter your phone number"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </FormControl>
          <FormControl id="message" isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea
              placeholder="Enter your message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </FormControl>
          <Flex w="full" justifyContent="flex-end">
            <Button
              colorScheme="blue"
              bg={buttonColor}
              type="submit"
              onClick={onSubmit}>
              Send
            </Button>
          </Flex>
        </VStack>
      </VStack>
      <span>{result}</span>
    </Container>
  );
};

export default ContactForm;