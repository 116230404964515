/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  List,
  ListItem,
  ListIcon,
  Divider,
  Flex,
  Avatar,
  useColorModeValue,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const ProfileSection = () => {
  const accentColor = useColorModeValue("blue.500", "blue.200");
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.3, // Adjust this value to trigger the effect sooner or later
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Container maxW="container.lg" py={10} ref={containerRef}>
      <VStack spacing={6} align="stretch" as={motion.div}
         
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={variants}
          transition={{
            duration: prefersReducedMotion ? 0 : 1, // Adjust duration as needed
            ease: "easeInOut",
          }}>
        {/* Header Section */}
        <MotionFlex
          justify="space-between"
          alignItems="center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.6, ease: "easeInOut" }}
          ref={containerRef}

        >
          <Box>
            <Heading size="lg">Mr. S. K. Navak</Heading>
            <Text fontSize="xl" color="gray.600">
              Director, 3S Energy Care & Consulting Services
            </Text>
          </Box>
          <Flex justify="center" alignItems="center">
            <Avatar
              size="2xl"
              src="/avatar.png"
              boxShadow="2xl"
              border={`4px solid ${accentColor}`}
              objectPosition="60% 85%"
            />
          </Flex>
        </MotionFlex>

        <Divider />

        {/* Education Section */}
        <MotionBox
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.6, ease: "easeInOut", delay: 1.2 }}
          ref={containerRef}

        >
          <Heading size="md" mb={2}>Education</Heading>
          <Text>B. Tech - Energy Management</Text>
          <Text>B.E. - Electrical Engineering</Text>
          <Text>
            Certified Energy Auditor by BEE, Govt. of India, Lead Assessor ISO: 50001 and 17025 (NABL) Certified M&V Professional by EVO & AEEE
          </Text>
        </MotionBox>

        {/* Employment History Section */}
        <MotionBox
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.6, ease: "easeInOut", delay: 1.4 }}
          ref={containerRef}

        >
          <Heading size="md" mb={2}>Employment History</Heading>
          <List spacing={3}>
            <ListItem>
              <Text>Apr 2019 – Onwards, 3S Energy Care & Consulting Services</Text>
            </ListItem>
            <ListItem>
              <Text>Feb 2008 – Mar 2019, IL&FS Environment</Text>
            </ListItem>
            <ListItem>
              <Text>Dec 1997 – Jan 2008, ERDA, Vadodara</Text>
            </ListItem>
          </List>
        </MotionBox>

        {/* Expertise Section */}
        <MotionBox
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.6, ease: "easeInOut", delay: 1.6 }}
          ref={containerRef}
        >
          <Heading size="md" mb={2}>Expertise</Heading>
          <List spacing={3}>
            <ListItem>
              <ListIcon as={FaCheckCircle} color="green.500" />
              Instrumental in implementing the ESCO projects through EPC (Energy Performance Contract) route.
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color="green.500" />
              Facilitated the implementation of the largest municipal energy efficiency project in the state of Gujarat covering 159 ULBs and 7 municipal corporations in the area of street lighting and water & sewage pumping systems.
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color="green.500" />
              Facilitating the implementation of large number of building E&E projects and other street lighting EE projects.
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color="green.500" />
              NABL Accreditation consultancy, as per ISO/IEC-17025-2017 and Testing Laboratory setup.
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color="green.500" />
              Lead Assessor, as per ISO: 50001 for Energy Management Systems.
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color="green.500" />
              Lead Assessor and Technical Assessor, as per ISO/IEC 17025:2017 (NABL Accreditation) and completed more than 250 assessments.
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color="green.500" />
              Contributed more than 10 technical papers in International and National Journals, Conferences and Seminars in the field of Energy Conservation and Renewable Energy.
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color="green.500" />
              Received National awards from PCRA, New Delhi (3 years in a row) and MEDA Maharashtra for outstanding performance in the area of Energy Conservation.
            </ListItem>
          </List>
        </MotionBox>

        {/* Clients Section */}
        <MotionBox
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.9, ease: "easeInOut", delay: 1.8 }}
        >
          <Heading size="md" mb={2}>Client List & Industry Experience</Heading>
          <Text>Extensive experience in conducting energy studies in various industries including:</Text>
          <List spacing={3} pl={5} styleType="disc">
            <ListItem>Voltas Ltd.</ListItem>
            <ListItem>Haier Appliances (AC, REF & Wgl Mfg Labs)</ListItem>
            <ListItem>Pune, Amber Enterprises</ListItem>
            <ListItem>Carrier Midea</ListItem>
            <ListItem>Saming-Chennai</ListItem>
            <ListItem>L.G. Pune</ListItem>
            <ListItem>Johnson Controls</ListItem>
            <ListItem>Kirloskar Chillers</ListItem>
            <ListItem>Siemond BlueBox, etc.</ListItem>
          </List>
        </MotionBox>
      </VStack>
    </Container>
  );
};

export default ProfileSection;
