import {
  Stack,
  Link,
  Image,
  IconButton,
  Text,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaLinkedin, FaMailBulk, FaTwitter } from "react-icons/fa";
import logo from './logo3s.png';

const links = [
  "Terms of use",
  "Privacy policy",
];

const accounts = [
  {
    url: "#",
    label: "Mail",
    type: "gray",
    icon: <FaMailBulk />,
  },
  {
    url: "#",
    label: "Twitter Account",
    type: "twitter",
    icon: <FaTwitter />,
  },
  {
    url: "#",
    label: "LinkedIn Account",
    type: "linkedin",
    icon: <FaLinkedin />,
  },
];

const Footer = () => {
  const display = useBreakpointValue({ base: "flex", md: "none" });
  
  return (
    <Stack
      maxW="5xl"
      marginInline="auto"
      p={8}
      spacing={{ base: 8, md: 6 }}
      justifyContent="space-between"
      alignItems="center"
      direction={{ base: "column", md: "row" }}
    >
      <Link href="/" isExternal>
        <Image
          w="100px"
          src={logo}
          alt="3s"
        />
      </Link>

      <Stack
        spacing={4}
        alignItems="center"
        direction={{ base: "column", md: "row" }}
        display={display}
      >
        {links.map((link, index) => (
          <Link key={index}>{link}</Link>
        ))}
      </Stack>

      

      <Box textAlign="center" mt={{ base: 4, md: 0 }}>
        <Text fontWeight="bold">Contact Us:</Text>
        <Text>Email: <Link href="mailto:3senergycare@gmail.com">3senergycare@gmail.com / </Link><Link href="mailto:sk.nayak@outlook.com">sk.nayak@outlook.com</Link></Text>
        <Text>Address: A-103, Earth Residency, Nr. Mathuranagari Soc., Sunpharma
        Road, Vadodara-390020, Gujarat</Text>
        <Text>Phone: +919909013148 
        </Text>
      </Box>

      <Box display={{ base: "none", md: "flex" }} alignItems="center">
        <Stack spacing={4} direction="row">
          {accounts.map((sc, index) => (
            <IconButton
              key={index}
              as={Link}
              isExternal
              href={sc.url}
              aria-label={sc.label}
              colorScheme={sc.type}
              icon={sc.icon}
              rounded="md"
            />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export default Footer;
